import { sendErrorMessage, sendSuccessMessage } from "./apiNotifyHandler";
import { responseHandlerInterceptor } from "./interceptors/response-handler.interceptor";
export class RedCrossCLient {
    init(client, accessTokenProvider) {
        this.client = client;
        this.accessTokenProvider = accessTokenProvider;
        return this;
    }
    addResponseHandlerInterceptor() {
        responseHandlerInterceptor(this.client, (status, method, message) => sendSuccessMessage(status, method, message), (status, message) => sendErrorMessage(status, message));
        return this;
    }
}
const redCrossCLient = new RedCrossCLient();
export default redCrossCLient;
