import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR, TAB_SIZE, THEME_TYPE_LITE } from "../../constants/ThemeSetting";
const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    const { width, themeType } = useSelector(({ settings }) => settings);
    let navStyle = useSelector(({ settings }) => settings.navStyle);
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
        navStyle = NAV_STYLE_DRAWER;
    }
    return (_jsxs("div", Object.assign({ className: "gx-layout-sider-header", style: { padding: "40px 80px" } }, { children: [(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? _jsx("div", Object.assign({ className: "gx-linebar" }, { children: _jsx("i", { className: `gx-icon-btn icon icon-${!sidebarCollapsed ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`, onClick: () => {
                        setSidebarCollapsed(!sidebarCollapsed);
                    } }) })) : null, _jsx(Link, Object.assign({ to: "/", className: "gx-site-logo" }, { children: navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
                    _jsx("img", { alt: "logo1", src: ("/assets/images/red-cross-logo.png"), style: { height: "110px", paddingTop: "20px" } }) :
                    themeType === THEME_TYPE_LITE ?
                        _jsx("img", { alt: "logo1", src: ("/assets/images/red-cross-logo.png"), style: { height: "110px", paddingTop: "20px" } }) :
                        _jsx("img", { alt: "logo1", src: ("/assets/images/red-cross-logo.png"), style: { height: "110px", paddingTop: "20px" } }) }))] })));
};
export default SidebarLogo;
