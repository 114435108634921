import { jsx as _jsx } from "react/jsx-runtime";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import configureStore, { history } from './appRedux/store';
import App from "./containers/App";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import config from 'devextreme/core/config';
import redCrossSettings from "./services/config/RedCrossSettings";
import { RedCrossAuthClient } from "./services/auth/RedCrossAuthClient";
import { redCrossAuthService } from "./services/auth/RedCrossAuthService";
import { AuthTokenCredential } from "./services/auth/Providers/AuthTokenCredential";
import { accessTokenProvider } from "./services/auth/Providers/AccessTokenProvider";
import { refreshTokenProvider } from "./services/auth/Providers/RefreshTokenProvider";
import redCrossClient from "services/appApi/RedCrossClient";
import redCrossUsersClient from "./services/usersApi/RedCrossUsersClient";
import { ApiClient } from "./restProviders/apiClient/apiClient";
import { UserClient } from "./restProviders/authClient";
const store = configureStore();
console.log("================<STARTING APP>=============");
redCrossSettings.getConfig();
const redCrossAuthClient = new RedCrossAuthClient("", redCrossSettings.AuthUrl.trim().concat('', '/connect/token'));
const authTokenCredential = new AuthTokenCredential(accessTokenProvider);
accessTokenProvider.init("access-token");
refreshTokenProvider.init("refresh-token");
redCrossAuthService.init(redCrossAuthClient, accessTokenProvider, refreshTokenProvider);
const clientOptions = {
    allowInsecureConnection: true,
    retryOptions: { maxRetries: 0 }
};
const apiCLient = new ApiClient(authTokenCredential, redCrossSettings.RedCrossApiUrl, clientOptions);
const usersClient = new UserClient(authTokenCredential, redCrossSettings.AuthUrl, clientOptions);
redCrossClient.init(apiCLient, accessTokenProvider).addResponseHandlerInterceptor();
redCrossUsersClient.init(usersClient, accessTokenProvider).addResponseHandlerInterceptor();
function NextApp() {
    loadMessages(ruMessages);
    locale("ru-RU");
    config({ defaultCurrency: 'RUB' });
    /*
    useEffect(() => {
        const initAuthObserver = async () => {
            await redCrossAuthService.initAccessTokenObserver(() => alert('no_auth'))
        };
        initAuthObserver();
    }, []);*/
    //TODO: сделать редирект на страницу логина
    redCrossAuthService.initAccessTokenObserver(() => { });
    return (_jsx(Provider, Object.assign({ store: store }, { children: _jsx(ConnectedRouter, Object.assign({ history: history }, { children: _jsx(Switch, { children: _jsx(Route, { path: "/", component: App }) }) })) })));
}
export default NextApp;
