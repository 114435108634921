var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class RedCrossAuthClient {
    constructor(clientId, baseUrl) {
        this.clientId = clientId;
        this.baseUrl = baseUrl;
    }
    authFromUserPassword(username, password) {
        const requestHeaders = new Headers();
        requestHeaders.set("Authorization", "Basic " + btoa(`${this.clientId}:`));
        const requestFormData = new URLSearchParams({
            username: username,
            password: password,
            grant_type: "password"
        });
        const config = {
            method: "POST",
            headers: requestHeaders,
            body: requestFormData
        };
        return new Promise((resolve, reject) => {
            fetching(() => fetch(`${this.baseUrl}`, config))
                .then(response => resolve(response))
                .catch((error) => {
                reject(error);
            });
        });
    }
    authAs(username, password, userId) {
        const requestHeaders = new Headers();
        requestHeaders.set("Authorization", "Basic " + btoa(`${this.clientId}:`));
        const requestFormData = new URLSearchParams({
            username: username,
            password: password,
            grant_type: "password",
            user_code: userId
        });
        const config = {
            method: "POST",
            headers: requestHeaders,
            body: requestFormData
        };
        return new Promise((resolve, reject) => {
            fetching(() => fetch(`${this.baseUrl}`, config))
                .then(response => resolve(response))
                .catch((error) => {
                reject(error);
            });
        });
    }
    authByRefreshToken(refreshToken) {
        const requestHeaders = new Headers();
        requestHeaders.set("Authorization", "Basic " + btoa(`${this.clientId}:`));
        const requestFormData = new URLSearchParams({
            refresh_token: refreshToken,
            grant_type: "refresh_token"
        });
        return new Promise((resolve, reject) => {
            const config = {
                method: "POST",
                headers: requestHeaders,
                body: requestFormData
            };
            fetching(() => fetch(this.baseUrl, config))
                .then(response => resolve(response))
                .catch((error) => reject(error));
        });
    }
}
export function fetching(callback) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield callback();
        if (response.status >= 200 && response.status <= 399) {
            return response;
        }
        else
            throw response;
    });
}
