import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { message, Select, Divider, Row } from 'antd';
import * as Icons from '@ant-design/icons';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import redCrossCLient from 'services/appApi/RedCrossClient';
import { redCrossAuthService } from "services/auth/RedCrossAuthService";
const SearchField = (props) => {
    const [initialData] = useState({
        tours: [],
        toursSearchResultCount: 0,
        tourGroups: [],
        tourGroupsSearchResultCount: 0,
        claims: [],
        claimsSearchResultCount: 0
    });
    const [initialMemberData] = useState({
        members: [],
        membersSearchResultCount: 0
    });
    const [initialClaimsData] = useState({
        claims: [],
        claimsSearchResultCount: 0
    });
    const [groupsLoading, setGroupsLoading] = useState(false);
    const [membersLoading, setMembersLoading] = useState(false);
    const [claimsLoading, setClaimsLoading] = useState(false);
    const [data, setData] = useState(initialData);
    const [memberData, setMemberData] = useState(initialMemberData);
    const [claimsData, setClaimsData] = useState(initialClaimsData);
    const [searchObject] = useState(() => new Subject());
    const [query, setQuery] = useState(null);
    const [memberSectionAccess, setMemberSectionAccess] = useState(false);
    useEffect(() => {
        setMemberSectionAccess(redCrossAuthService.isSltAdmin() || redCrossAuthService.isSysAdmin() || redCrossAuthService.isMGT());
        const sub = searchObject.pipe(debounceTime(500), map(v => v.trim()), distinctUntilChanged()).subscribe(next => {
            if (!next) {
                setData(initialData);
                setMemberData(initialMemberData);
                setClaimsData(initialClaimsData);
                return;
            }
            setGroupsLoading(true);
            setMembersLoading(true);
            setClaimsLoading(true);
            setQuery(next);
            redCrossCLient.client.searchTourOrTourGroupById({ query: next })
                .then(response => {
                setGroupsLoading(false);
                setData(response.result);
            })
                .catch(error => message.error(error));
            if (memberSectionAccess) {
                redCrossCLient.client.searchMembers({ query: next })
                    .then(response => {
                    setMembersLoading(false);
                    setMemberData(response.result);
                })
                    .catch(error => message.error(error));
            }
            else {
                setMembersLoading(false);
            }
            redCrossCLient.client.searchClaims({ query: next })
                .then(response => {
                setClaimsLoading(false);
                setClaimsData(response.result);
            })
                .catch(error => message.error(error));
        });
        return () => sub.unsubscribe();
    });
    const handleSearch = (newValue) => {
        if (newValue) {
            searchObject.next(newValue);
        }
        else {
            setData(initialData);
            setMemberData(initialMemberData);
            setClaimsData(initialClaimsData);
            setQuery(null);
        }
    };
    const isLoading = () => groupsLoading || membersLoading || claimsLoading;
    const renderSelectData = () => {
        var _a;
        return _jsxs(_Fragment, { children: [_jsx(Select.OptGroup, Object.assign({ label: _jsx(_Fragment, { children: _jsxs(Row, Object.assign({ justify: 'space-between', align: 'top', style: { margin: 0, width: "100%" } }, { children: [_jsx("span", { children: "\u0422\u0443\u0440\u044B" }), !!data.tours && data.tours.length < data.toursSearchResultCount && !!query && query !== "" &&
                                    _jsxs(Link, Object.assign({ to: { pathname: `/search?query=${query}`, hash: "tours" }, title: "\u0412\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B" }, { children: ["\u0412\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B (", data.toursSearchResultCount, ")"] })), (!data.tours || data.tours.length == 0) && !!query && query !== "" && !isLoading() &&
                                    _jsx("span", Object.assign({ style: { color: "rgba(0,0,0,.25)" } }, { children: "\u041D\u0435\u0442 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432" })), (!data.tours || data.tours.length == 0) && !!query && query !== "" && isLoading() &&
                                    _jsx("span", Object.assign({ className: "slt-blink", style: { color: "rgba(0,0,0,.25)" } }, { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430" }))] })) }) }, { children: !!data.tours && data.tours.length !== 0 && !!query && _jsx(_Fragment, { children: (_a = data === null || data === void 0 ? void 0 : data.tours) === null || _a === void 0 ? void 0 : _a.map((i, index) => _jsx(Select.Option, { children: _jsx(Link, Object.assign({ to: `/touroperator/tour/${i.externalTourId}`, title: `Тур "${i.title}"` }, { children: i.title })) }, `${undefined}_${index}`)) }) })), _jsx(Select.Option, { children: _jsxs(Link, Object.assign({ to: "/touroperator/tours" }, { children: [_jsx(Icons.SearchOutlined, { style: { marginRight: '.5rem' } }), "\u0412\u0441\u0435 \u0442\u0443\u0440\u044B"] })) }, "allTours"), _jsx(Select.Option, Object.assign({ disabled: true, style: { minHeight: 0 } }, { children: _jsx(Divider, { plain: true, style: { margin: 0 } }) }), "divider-1"), _jsx(Select.OptGroup, Object.assign({ label: _jsx(_Fragment, { children: _jsxs(Row, Object.assign({ justify: 'space-between', align: 'top', style: { margin: 0, width: "100%" } }, { children: [_jsx("span", { children: "\u0413\u0440\u0443\u043F\u043F\u044B" }), !!data.tourGroups && data.tourGroups.length < data.tourGroupsSearchResultCount && !!query && query !== "" &&
                                    _jsxs(Link, Object.assign({ to: { pathname: `/search?query=${query}`, hash: "tour-groups" }, title: "\u0412\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B" }, { children: ["\u0412\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B (", data.tourGroupsSearchResultCount, ")"] })), (!data.tourGroups || data.tourGroups.length == 0) && !!query && query !== "" && !isLoading() &&
                                    _jsx("span", Object.assign({ style: { color: "rgba(0,0,0,.25)" } }, { children: "\u041D\u0435\u0442 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432" })), (!data.tourGroups || data.tourGroups.length == 0) && !!query && query !== "" && isLoading() &&
                                    _jsx("span", Object.assign({ className: "slt-blink", style: { color: "rgba(0,0,0,.25)" } }, { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430" }))] })) }) }, { children: !!data.tourGroups && data.tourGroups.length !== 0 && !!query && _jsx(_Fragment, { children: data.tourGroups.map((i, index) => _jsx(Select.Option, { children: _jsx(Link, Object.assign({ to: `/touroperator/group/${i.tourGroupId}`, title: `Группа ${i.externalTourGroupId} тура ${i.tourName}` }, { children: `Группа ${i.externalTourGroupId} тура ${i.tourName}` })) }, `${i.externalTourGroupId}_${index}`)) }) })), _jsx(Select.Option, { children: _jsxs(Link, Object.assign({ to: "/touroperator/groups" }, { children: [_jsx(Icons.SearchOutlined, { style: { marginRight: '.5rem' } }), "\u0412\u0441\u0435 \u0433\u0440\u0443\u043F\u043F\u044B"] })) }, "allTourGroups"), memberSectionAccess && _jsxs(_Fragment, { children: [_jsx(Select.Option, Object.assign({ disabled: true, style: { minHeight: 0 } }, { children: _jsx(Divider, { plain: true, style: { margin: 0 } }) }), "divider-2"), _jsx(Select.OptGroup, Object.assign({ label: _jsx(_Fragment, { children: _jsxs(Row, Object.assign({ justify: 'space-between', align: 'top', style: { margin: 0, width: "100%" } }, { children: [_jsx("span", { children: "\u0423\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0438" }), !!memberData.members && memberData.members.length < memberData.membersSearchResultCount && !!query && query !== "" &&
                                            _jsxs(Link, Object.assign({ to: { pathname: `/search?query=${query}`, hash: "members" }, title: "\u0412\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B" }, { children: ["\u0412\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B (", memberData.membersSearchResultCount, ")"] })), (!memberData.members || memberData.members.length == 0) && !!query && query !== "" && !isLoading() &&
                                            _jsx("span", Object.assign({ style: { color: "rgba(0,0,0,.25)" } }, { children: "\u041D\u0435\u0442 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432" })), (!memberData.members || memberData.members.length == 0) && !!query && query !== "" && isLoading() &&
                                            _jsx("span", Object.assign({ className: "slt-blink", style: { color: "rgba(0,0,0,.25)" } }, { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430" }))] })) }) }, { children: !!memberData.members && memberData.members.length !== 0 && !!query && _jsx(_Fragment, { children: memberData.members.map((i, index) => _jsx(Select.Option, { children: _jsx(Link, Object.assign({ to: `/admin/members/${i.memberId}`, title: `${i.lastName} ${i.firstName} ${i.middleName} ${i.birthDateFormatted}` }, { children: `${i.fullName} (${i.birthDateFormatted})` })) }, `${i.memberId}_${index}`)) }) })), _jsx(Select.Option, { children: _jsxs(Link, Object.assign({ to: "/admin/members" }, { children: [_jsx(Icons.SearchOutlined, { style: { marginRight: '.5rem' } }), "\u0412\u0441\u0435 \u0443\u0447\u0430\u0441\u0442\u043D\u0438\u043A\u0438"] })) }, "allMembers")] }), _jsx(Select.Option, Object.assign({ disabled: true, style: { minHeight: 0 } }, { children: _jsx(Divider, { plain: true, style: { margin: 0 } }) }), "divider-3"), _jsx(Select.OptGroup, Object.assign({ label: _jsx(_Fragment, { children: _jsxs(Row, Object.assign({ justify: 'space-between', align: 'top', style: { margin: 0, width: "100%" } }, { children: [_jsx("span", { children: "\u0417\u0430\u044F\u0432\u043A\u0438" }), !!claimsData.claims && claimsData.claims.length < claimsData.claimsSearchResultCount && !!query && query !== "" &&
                                    _jsxs(Link, Object.assign({ to: { pathname: `/touroperator/claims/search/${query}` }, title: "\u0412\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B" }, { children: ["\u0412\u0441\u0435 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B (", claimsData.claimsSearchResultCount, ")"] })), (!claimsData.claims || claimsData.claims.length == 0) && !!query && query !== "" && !isLoading() &&
                                    _jsx("span", Object.assign({ style: { color: "rgba(0,0,0,.25)" } }, { children: "\u041D\u0435\u0442 \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u043E\u0432" })), (!claimsData.claims || claimsData.claims.length == 0) && !!query && query !== "" && isLoading() &&
                                    _jsx("span", Object.assign({ className: "slt-blink", style: { color: "rgba(0,0,0,.25)" } }, { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430" }))] })) }) }, { children: !!claimsData.claims && claimsData.claims.length !== 0 && !!query && _jsx(_Fragment, { children: claimsData.claims.map((i, index) => _jsx(Select.Option, { children: _jsx(Link, Object.assign({ to: `/touroperator/claims/${i.bookingCenterClaimId}`, title: `${i.claimNumber}` }, { children: `${i.claimNumber}` })) }, `${i.bookingCenterClaimId}_${index}`)) }) })), _jsx(Select.Option, { children: _jsxs(Link, Object.assign({ to: "/touroperator/claims" }, { children: [_jsx(Icons.SearchOutlined, { style: { marginRight: '.5rem' } }), "\u0412\u0441\u0435 \u0437\u0430\u044F\u0432\u043A\u0438"] })) }, "allClaims")] });
    };
    return (_jsx(_Fragment, { children: _jsx(Select, Object.assign({ showSearch: true, suffixIcon: _jsxs(_Fragment, { children: [!isLoading() && _jsx(Icons.SearchOutlined, {}), isLoading() && _jsx(Icons.LoadingOutlined, {})] }), value: query, loading: groupsLoading, placeholder: props.placeholder, style: props.style, defaultActiveFirstOption: false, showArrow: true, filterOption: false, onDropdownVisibleChange: (state) => setQuery(state ? query : null), onSearch: handleSearch }, { children: renderSelectData() })) }));
};
export default SearchField;
