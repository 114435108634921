class RedCrossSettings {
    constructor() {
        this.RedCrossApiUrl = '';
        this.AuthUrl = '';
        this.StrapiUrl = '';
    }
    getConfig() {
        this.RedCrossApiUrl = process.env.REACT_APP_API_URL;
        this.AuthUrl = process.env.REACT_APP_AUTH_URL;
        this.StrapiUrl = process.env.REACT_APP_STRAPI_URL;
    }
}
export default new RedCrossSettings();
